import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.css"]
})
export class LocationsComponent implements OnInit {
  @Input() inspection: any;
  constructor() { }

  ngOnInit() { }
}
