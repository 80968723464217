import { Component, OnInit } from "@angular/core";
import { Store, Select } from "@ngxs/store";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"]
})
export class DialogComponent implements OnInit {
  user: any;
  constructor(private store: Store) {}
  ngOnInit() {
    this.user = this.store.selectSnapshot(state => state.auth.user);
    console.log(this.user);
  }
}
