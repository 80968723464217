import { Component, OnInit } from "@angular/core";
import { Store, Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { HipState, GetInspections } from "../store";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"]
})
export class MapComponent implements OnInit {
  constructor(private store: Store) { }
  public lat: number = 62.80636613511151;
  public lng: number = -92.0885354760998;
  public zoom: number = 13;

  user: any;
  community: string;

  @Select(HipState.InventoryEntities) inventory$: Observable<any>;
  @Select(HipState.InspectionsArray) inspection$: Observable<any>;

  ngOnInit() {
    this.user = this.store.select(state => state.auth.user);
    let community = (this.community = this.store.selectSnapshot(
      state => state.auth.community
    ));
    this.store.dispatch(new GetInspections({ community }));
  }

  latlng(inspection) {
    return typeof inspection.geolocation !== "undefined";
  }

  _lat(inspection) {
    if (inspection && inspection.geolocation)
      return inspection.geolocation[0].lat;
    return;
  }

  _long(inspection) {
    if (inspection && inspection.geolocation)
      return inspection.geolocation[0].long;
    return;
  }
}
