import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import {

  tap,

  catchError
} from "rxjs/operators";
import { Store } from "@ngxs/store";

@Injectable()
export class AuditService {
  constructor(private http: HttpClient, private store: Store) { }

  updateLog(id: string, action: any): Observable<any> {
    let headers = this.store.selectSnapshot(state => state.auth.xauth);

    return this.http
      .post(`https://hms-db0.nunavuthousing.ca/hms_logs`,{
        user: id,
        action: action,
        ts: Math.floor(Date.now() / 1000)
      }, {
        headers: headers
      })
      .pipe(
        catchError(err => {
          throw (err);
        }),
        tap(x => console.log("action logged",x))
      );

    //)

    //return arr;
  }
}
