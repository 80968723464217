import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import {
  map,
  filter,
  tap,
  debounceTime,
  distinctUntilChanged
} from "rxjs/operators";
import { Inspection, Inventory } from "./../models/";
import * as moment from "moment";
import { Store } from "@ngxs/store";
import {
  SortInspections,
  FilterInspections,
  HipState,
  FilterInspectionsByComponent
} from "../store";
import { MatPaginator, MatSort, MatButton, MatSelect } from "@angular/material";
import * as XLSX from "xlsx";
import { ReportingService } from "../services";

/**  TODO - move all smarts to the inventory module.
     This should be a dumb component, all state calls etc.
     should be done by the container module.
**/
@Component({
  selector: "grid-component",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("TABLE") table: ElementRef;
  exportAsExcel() {
    this.store
      .selectOnce(HipState.InspectionsArray)
      .toPromise()
      .then(data => {
        let exportData = data.map(inspection => ({
          Community: inspection.community,
          Building: this.inv_lookup(inspection.unit).address.number,
          Unit: this.inv_lookup(inspection.unit).unit.number,
          UCR: inspection.UCR ? inspection.UCR.watson : "",
          Mold: this.mold_check(inspection) ? "mold" : "",
          "Tenant Damage": this.td_check(inspection) ? "td" : "",
          "H&S": this.hs_check(inspection) ? "hs" : "",
          Completed: inspection.completed ? new Date(inspection.completed) : ""
        }));
        console.log(exportData);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Inspections");

        /* save to file */
        XLSX.writeFile(wb, "HIP export.xlsx");
      });
  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayColumns = [
    "community",
    "building",
    "unit",
    "template",
    "createdBy",
    "progress",
    "UCR",
    "Mold",
    "Tenant Damage",
    // "health_safety",
    "completed",
    "images",
    "commands"
  ];

  /** TODO all inputs should be non-obsverables **/
  @Input() inspections: Observable<Inspection[]>;
  @Input() inventory: any;
  @Input() loading: Observable<boolean>;
  @Input() printing: Observable<boolean>;
  @Input() community: string;
  @Input() stats: number[];

  @Input() hms: any;

  @Input() components: any[];

  @Input() average: number;
  @Input() sorting: any;

  @Output() printReport = new EventEmitter();
  @Output() viewReport = new EventEmitter();
  @Output() viewPhotos = new EventEmitter();
  @Output() dataFilter = new EventEmitter();

  componentsArray: any[];
  searchTextChange: Subject<any>;

  constructor(public reporting: ReportingService, public store: Store) {}

  ngOnChanges() {
    /** TODO: filter the components down to only the ones used in the inspection
        templates that the current logged in user has access to.
        **/
    if (this.components)
      this.componentsArray = [
        { _id: "None", name: "None" },
        ...this.components
      ];
    //
    // console.log(this.components);
  }

  ngOnInit() {
    this.searchTextChange = new Subject<string>();
    this.searchTextChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(text => {
        this.store.dispatch(new FilterInspections({ text }));
      });
  }

  // this should be moved to the inspection.component possibly
  inv_lookup(id: string) {
    let building = id.split("-")[0];
    if (this.inventory[building])
      return {
        ...this.inventory[building],
        unit: this.inventory[building].units[id.split("-")[1]]
      };
  }
  sortChange(event) {
    console.log("sort", event);
    this.store.dispatch(
      new SortInspections({ column: event.active, direction: event.direction })
    );
  }

  pageChange(event) {
    console.log("page", event);
  }

  applyFilter(text) {
    this.searchTextChange.next(text);
  }

  componentFilter(componentId) {
    console.log("componentFilter", componentId);
    if (componentId != "None")
      this.store.dispatch(
        new FilterInspectionsByComponent({ id: componentId })
      );
    else this.store.dispatch(new FilterInspectionsByComponent({ id: false }));
  }

  calc_watson(doc) {
    // if (doc && this.inv_lookup(doc.unit))
    //   return this.reporting.calc_watson(doc, this.hms, this.inv_lookup(doc.unit).unit)
  }

  mold_check(inspection) {
    if (inspection)
      return JSON.stringify(inspection).indexOf('"Mold":"Mold"') != -1;
  }

  td_check(inspection) {
    if (inspection) return JSON.stringify(inspection).indexOf('"TD"') != -1;
  }

  hs_check(inspection) {
    if (inspection)
      return (
        inspection.warnings &&
        inspection.warnings.health &&
        inspection.warnings.health.length > 0
      );
  }

  imageCount(inspection) {
    if (inspection._attachments)
      return Object.keys(inspection._attachments).length;
  }

  view_photos(inspection) {
    this.viewPhotos.emit(inspection);
  }
  view_report(inspection) {
    this.viewReport.emit(inspection);
  }

  print_report(inspection) {
    this.printReport.emit(inspection);
  }
}
