import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";
import { ngCopy } from "angular-6-clipboard";

@Component({
  selector: "photo-overlay",
  templateUrl: "./photo-overlay.component.html",
  styleUrls: ["./photo-overlay.component.css"]
})
export class PhotoOverlayComponent implements OnInit, OnChanges {
  constructor() {}

  removingImage: string | boolean;
  @Input() photos: string[];
  @Input() inspection: any;

  @Output() close_overlay = new EventEmitter();
  @Output() remove_attachment = new EventEmitter();

  edit: boolean = false;

  confirmEditMode() {
    if (!this.edit) {
      let c = confirm("Entering edit mode, are you sure?");
      if (c === true) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    } else {
      this.edit = false;
    }
  }
  remove(inspection, photoId) {
    //ngCopy(text);
    let c = confirm(
      "Are you sure you want to remove this image?  This can not be undone, and will be logged as removed by you."
    );
    console.log("confirm", c);
    if (c == true) {
      this.removingImage = "Removing The Image";
      this.remove_attachment.emit({
        inspection: inspection,
        attachmentId: photoId
      });
    }
  }
  readable(photo) {
    if (!photo) return;
    // format the id to human readable text
    let arr = photo.replace(/_/g, " ").split("");
    arr[photo.match(/\d+.jpg/).index]++;
    arr.splice(photo.match(/\d+.jpg/).index, 0, "#");

    console.log(arr.join("").split(".jpg")[0]);
    return arr.join("").split(".jpg")[0];
  }

  closeOverlay() {
    console.log("  close");
    this.close_overlay.emit(true);
  }
  ngOnInit() {
    console.log(this.photos, this.inspection);
  }

  ngOnChanges() {
    this.removingImage = false;
  }
}
