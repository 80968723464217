import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "communityFilter",
  pure: false
})
export class CommunityPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items
      .filter(item => item.community && item.community.indexOf(filter) !== -1)
      .sort((a, b) => b.completed - a.completed);
  }
}
