import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { UserService, User } from "./user.service";

import { InventoryDataService } from "./inventory.data.service";

import { Store } from "@ngxs/store";
import { Observable, of, throwError } from "rxjs";
import {
  map,
  pluck,
  tap,
  mergeAll,
  combineAll,
  filter,
  switchMap,
  take,
  retry,
  catchError
} from "rxjs/operators";
import { Inspection } from "../models";

@Injectable()
export class InspectionDataService {
  constructor(
    private userService: UserService,
    private http: HttpClient,
    private inventoryService: InventoryDataService,
    private store: Store
  ) {}

  ngOnInit() {}
  //
  //inventoryDataService: any;

  getAll(community, remote): Observable<Inspection[]> {
    console.log(community);
    let headers = this.store.selectSnapshot(state => state.auth.xauth);
    let userTemplates = this.store.selectSnapshot(
      state => state.auth.user.hip.templates
    );

    //this.inventoryDataService = InspectionDataService;
    if (community)
      return this.http
        .get(
          `https://hms-db${
            remote ? "0" : ""
          }.nunavuthousing.ca/${community
            .toLowerCase()
            .replace(" ", "_")}_hip/_all_docs?include_docs=true`,
          { headers: headers }
        )
        .pipe(
          retry(2),
          catchError(err => {
            return throwError(err);
          }),
          pluck("rows"),
          mergeAll(), // this takes the resulting array and merges all elements into a new obeservable
          filter(
            row =>
              row["doc"].doctype == "inspection" &&
              (userTemplates.indexOf(row["doc"].template) > -1 ||
                // check edgecase crs_archive and user has public housing template TODO: this needs to be made more generic
                (row["doc"].crs_archive &&
                  userTemplates.indexOf("template_public_annual") > -1))
          ),
          map(row => [{ ...row["doc"], id: row["id"] }]),
          combineAll(), // this combines all mapped rows into a single array observable again.
          tap(x => console.log(x))
        );
    else return of([]);

    //)

    //return arr;
  }

  getById(community, id): Observable<Inspection> {
    console.log(community);
    let headers = this.store.selectSnapshot(state => state.auth.xauth);
    let remote = this.store
      .selectSnapshot(state => state.hip.remotes)
      .includes(community);
    //this.inventoryDataService = InspectionDataService;
    if (community)
      return this.http
        .get(
          `https://hms-db${
            remote ? "0" : ""
          }.nunavuthousing.ca/${community
            .toLowerCase()
            .replace(" ", "_")}_hip/${id}`,
          { headers: headers }
        )
        .pipe(
          retry(2),
          tap(x => console.log(x)),
          catchError(err => {
            // simple logging, but you can do a lot more, see below
            console.error("An error occurred:", err.error);
            return of(err);
          })
        );
    else return of();

    //)

    //return arr;
  }

  getCommunityAverageCR(community): Observable<any> {
    let headers = this.store.selectSnapshot(state => state.auth.xauth);
    let remote = this.store
      .selectSnapshot(state => state.hip.remotes)
      .includes(community);
    console.count("getUCR Service");
    if (community)
      return this.http
        .get(
          `https://hms-db${
            remote ? "0" : ""
          }.nunavuthousing.ca/${community
            .toLowerCase()
            .replace(" ", "_")}_hip/_design/status/_view/avg_cr?group=true`,
          { headers: headers }
        )
        .pipe(
          retry(2),
          pluck("rows"),
          tap(x => console.log(x)),
          map(
            (docs: any) =>
              docs.reduce((a, c) => a + c.value.UCR, 0) / docs.length
          ),
          tap(x => console.log(x)),
          catchError(err => {
            // simple logging, but you can do a lot more, see below
            console.error("An error occurred:", err.error);
            return of();
          })
        );
    else return of();
  }

  // getAttachment(id: string, attachment?: string) {
  //   let community = this.userService.user.selectedCommunity;
  //
  //   if (community)
  //     return super.execute(
  //       "GET",
  //       `https://hms-db.nunavuthousing.ca/${community
  //         .toLowerCase()
  //         .replace(" ", "_")}_hip/${id}/${attachment}`,
  //       {},
  //       { headers: { Authorization: "Basic cmVwbGljYXRlOlIzcGxpY2F0ZQ==" } }
  //     );
  // }

  // getWithQuery(params: string | QueryParams): Observable<Inspection[]> {
  //   return super
  //     .getWithQuery(params)
  //     .pipe(
  //       map(Inspections =>
  //         Inspections.map(Inspection => this.mapInspection(Inspection))
  //       )
  //     );
  // }

  private mapInspection(meta: any): Inspection {
    return { ...meta.rows };
  }
}
