import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { retryWhen, retry, delay, catchError, map, tap } from "rxjs/operators";
import { Observable, of, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { AuthState } from "../store";

export interface Xauth {
  "X-Auth-CouchDB-UserName"?: string;
  "X-Auth-CouchDB-Token"?: string;
  "X-Auth-CouchDB-Roles"?: string;
}
const SERVER_URL = "https://hms-db0.nunavuthousing.ca";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public error = new BehaviorSubject<boolean>(false);
  xauth: any;

  constructor(private http: HttpClient, private store: Store) {}

  login({ username, password }) {
    username = username.split("@")[0]; // trim off the email domain if present

    return this.http
      .post(`${SERVER_URL}/login`, { username: username, password: password })

      .pipe(
        retry(2),
        catchError(val => {
          return of({ status: val.status });
        })
      );
  }

  getUser({ username }) {
    const HEADERS = this.store.selectSnapshot<any>(state => state.auth.xauth);
    console.log("headers", HEADERS);
    return this.http.get(`${SERVER_URL}/hms/${username}`, {
      headers: HEADERS
    });
  }
}
