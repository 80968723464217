import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CommunityPipe } from "./inspection.pipes";

import { InspectionComponent } from "./inspection.component";
import { GridComponent } from "./grid.component";
import { OverlayComponent } from "../overlay/overlay.component";
import { PhotoOverlayComponent } from "../photo-overlay/photo-overlay.component";

import { MenuComponent } from "./menu.component";
import { ReportComponent } from "../report/report.component";
import { InventoryDetailComponent } from "../report/inventory-detail/inventory-detail.component";
import { InspectionOverviewComponent } from "../report/inspection-overview/inspection-overview.component";
import { LocationsComponent } from "../report/locations/locations.component";
import {
  MatTableModule,
  MatButtonModule,
  MatChipsModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatBadgeModule,
  MatSortModule,
  MatSelectModule,
  MatToolbarModule,
  MatDialogModule,
  MatListModule,
  MatProgressBarModule,
  MatProgressSpinnerModule
} from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    MatSortModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    GridComponent,
    InspectionComponent,
    MenuComponent,
    CommunityPipe,
    OverlayComponent,
    PhotoOverlayComponent,
    ReportComponent,
    InventoryDetailComponent,
    InspectionOverviewComponent,
    LocationsComponent,
    DialogComponent
  ],
  entryComponents: [DialogComponent]
})
export class InspectionModule {}
