import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "inspection-overview",
  templateUrl: "./inspection-overview.component.html",
  styleUrls: ["./inspection-overview.component.css"]
})
export class InspectionOverviewComponent implements OnInit {
  @Input() inspection: any;

  constructor() { }

  ngOnInit() { }
}
