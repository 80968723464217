import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { AuthState, HipState } from "./store";
import { AuthGuard } from "./guards";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { InspectionModule } from "./inspections/inspection.module";
import { AuditService } from "./services";
import { LoginComponent } from "./login/login.component";
import { AdminModule } from "./admin/admin.module";
import { MapModule } from "./map/map.module";
import {
  MatTableModule,
  MatSortModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatProgressBarModule
} from "@angular/material";

import {
  InspectionDataService,
  InventoryDataService,
  HmsDataService,
  AttachmentDataService,
  ReportingService
} from "./services";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";

export const ROUTES: Routes = [
  { path: "", pathMatch: "full", redirectTo: "inspections" }
];

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    NgxsStoragePluginModule.forRoot({
      key: "auth",
      storage: StorageOption.SessionStorage
    }),
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AuthState, HipState]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    InspectionModule,
    NgxsRouterPluginModule.forRoot(),
    RouterModule.forRoot(ROUTES, { useHash: true }),
    AdminModule,
    MapModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  providers: [
    //PDFService,
    AuthGuard,
    InspectionDataService,
    InventoryDataService,
    HmsDataService,
    AttachmentDataService,
    ReportingService,
    AuditService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
