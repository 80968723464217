import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

import { AuthState } from "../store";
import { Store } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";
import { UserService } from "../services/user.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private userService: UserService) {}

  // improve this, either query the server each time, or check on timeout
  // and remove xauth when invalidated
  canActivate() {
    const auth = this.store.selectSnapshot(state => state.auth);
    console.log(auth);
    if (auth && auth["xauth"]) {
      // we have xuath tokens, check user

      return true;
    } else {
      console.log("authguard:", auth);
      this.store.dispatch(new Navigate(["/login"]));
      return false;
    }
  }
}
