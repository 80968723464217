import { State, Action, Selector, StateContext, Store } from "@ngxs/store";
import { AuthService, Xauth, AuditService } from "../services";
import { tap } from "rxjs/operators";
import { Navigate } from "@ngxs/router-plugin";
import * as moment from "moment"

export class AuthStateModel {
  xauth?: Xauth;
  username?: string;
  user?: any;
  community?: string;
  status?: number;
  loading: boolean;
}
export class Login {
  static readonly type = "[Auth] Login";
  constructor(public payload: { username: string; password: string }) {}
}

export class GetUser {
  static readonly type = "[Auth] GetUser";
  constructor(public payload: { username: string }) {}
}
export class Logout {
  static readonly type = "[Auth] Logout";
  constructor(public payload: { error: number }) {}
}

export class ChangeCommunity {
  static readonly type = "[Auth] ChangeCommunity";
  constructor(public payload: { community: string }) {}
}

@State<AuthStateModel>({
  name: "auth"
})
export class AuthState {
  constructor(private authService: AuthService, private store: Store, private audit: AuditService) {}
  @Action(Login)
  login(
    ctx: StateContext<AuthStateModel>,
    { payload: { username, password } }: Login
  ) {
    ctx.patchState({loading: true});
    return this.authService.login({ username, password }).pipe(
      tap((xauth: Xauth) => {
        console.log("auth return", xauth);
        // check for catch
        if (xauth["status"] == 401) {
          // 401 - not authorized
          ctx.patchState({ status: xauth["status"] , loading: false});
          console.log("Not Authorized");
        } else if (xauth["X-Auth-CouchDB-Roles"].indexOf("hms-db-users") > -1) {
          // check for hms-db-user group
          ctx.patchState({ xauth: xauth, username: username, status: 200 , loading: false});
          this.audit.updateLog(username,"logged In").subscribe(()=>{console.log("audit")})
          ctx.dispatch([
            new GetUser({ username }),
            new Navigate(["inspections"])
          ]);
        } else {
          ctx.patchState({ xauth: null, username: null, loading: false });
          ctx.dispatch(new Navigate(["/login"]));
        }
      })
    );
  }

  @Action(GetUser)
  getUser(
    ctx: StateContext<AuthStateModel>,
    { payload: { username } }: GetUser
  ) {
    return this.authService.getUser({ username }).pipe(
      tap((user: any) => {
        ctx.patchState({ user: user });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, { payload: { error } }: Logout) {
    ctx.patchState({
      xauth: null,
      username: null,
      status: error,
      user: null,
      community: null
    });
    ctx.dispatch(new Navigate(["/login"]));
  }

  @Action(ChangeCommunity)
  changeCommunity(
    ctx: StateContext<AuthStateModel>,
    { payload: { community } }: ChangeCommunity
  ) {
    // check if user has access to community (safety check)
    let communities = ctx.getState().user.community;
    if (communities.indexOf(community) != -1) {
      ctx.patchState({ community });
    }
  }

  @Selector()
  static xauth(state: AuthStateModel) {
    return state.xauth;
  }

  @Selector()
  static loading(state: AuthStateModel){
    return state.loading;
  }

  @Selector()
  static user(state: AuthStateModel) {
    return state.user;
  }
  @Selector()
  static status(state: AuthStateModel) {
    return state.status;
  }
}
