import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, pluck, tap, mergeAll, combineAll, filter } from "rxjs/operators";
import { Inventory } from "../models";
import { Store } from "@ngxs/store";

@Injectable()
export class InventoryDataService {
  constructor(private http: HttpClient, private store: Store) { }

  getAll(): Observable<Inventory[]> {
    let community = this.store.selectSnapshot(state => state.auth.community);
    let headers = this.store.selectSnapshot(state => state.auth.xauth);
    let remote = this.store
      .selectSnapshot(state => state.hip.remotes)
      .includes(community);
    if (community)
      this.http
        .get(
          `https://hms-db${
          remote ? "0" : ""
          }.nunavuthousing.ca/${community
            .toLowerCase()
            .replace(
              " ",
              "_"
            )}_inv/_all_docs?include_docs=true&attachments=true`,
          {
            headers: headers
          }
        )
        .pipe(
          pluck("rows"),
          mergeAll(), // this takes the resulting array and merges all elements into a new obeservable
          filter(row => row["doc"].doctype == "building"), // this would filter to only completed inspections
          map(row => [{ ...row["doc"], id: [community, row["id"]] }]),
          combineAll(), // this combines all mapped rows into a single array observable again.
          tap(x => console.log(x))
        );
    else return of([]);
  }

  getById(id): Observable<Inventory> {
    let community = this.store.selectSnapshot(state => state.auth.community);
    let headers = this.store.selectSnapshot(state => state.auth.xauth);
    let remote = this.store
      .selectSnapshot(state => state.hip.remotes)
      .includes(community);
    console.log("getbyid", community, Array.from(new Set(id)));
    return this.http
      .post<any>(
        `https://hms-db${
        remote ? "0" : ""
        }.nunavuthousing.ca/${community
          .toLowerCase()
          .replace(" ", "_")}_inv/_all_docs?include_docs=true&attachments=true`,
        { keys: Array.from(new Set(id)) },
        {
          headers: {
            ...headers
          }
        }
      )
      .pipe(pluck("rows"));
  }
}
