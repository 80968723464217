import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";

import { UserService } from "./services";

import { Inspection } from "./models";
import { Observable } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  constructor(private userService: UserService) {}

  ngOnInit() {}
}
