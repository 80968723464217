import {
  Component,
  ChangeDetectionStrategy,
  NgZone,
  ChangeDetectorRef
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthState, Logout, Login } from "../store";
import { Store, Actions, ofActionDispatched, Select } from "@ngxs/store";
import { Router } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { tap } from "rxjs/operators";
import { UserService, User } from "../services";
import { Observable } from "rxjs";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.css"]
  //providers: [UserService]
})
export class LoginComponent {
  constructor(
    private actions: Actions,
    private router: Router,
    private store: Store
  ) {}
  public error: boolean;
  @Select(AuthState.status) status$: Observable<any>;
  @Select(AuthState.loading) loading$: Observable<boolean>;

  ngOnInit() {
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate(["/login"]);
    });
  }

  submitLogin(f) {
    this.store.dispatch(
      new Login({ username: f.value.username, password: f.value.password })
    );
  }
}
