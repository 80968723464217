import { Inject, Injectable } from "@angular/core";
import { retryWhen, retry, delay, catchError, map, tap } from "rxjs/operators";
import { Observable, of, BehaviorSubject } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Xauth} from "./"

import { AuthService } from "./auth.service";

export interface User {
  username: string;
  community: string[];
  xauth: Xauth;
  selectedCommunity: string;
}



const STORAGE_KEY = "auth";
const SERVER_URL = "https://hms-db.nunavuthousing.ca";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private store: Store, private http: HttpClient) {}

  login(username, password) {}
  public user: User;
  public Data: object = {};

  checkUser() {
    let headers = this.store.selectSnapshot(state => state.auth.xauth);
    let auth = this.store.selectSnapshot(state => state.auth);

    console.log(auth);
    if (auth && auth.xauth) {
      //   // test against _session
      this.http
        .get<any>(`${SERVER_URL}/hms/${auth.username}`, { headers: headers })
        .pipe(
          //map(data => !!data.id),
          tap(data => {
            this.user = data;
            console.log(data);
            return of(true);
          }),
          catchError(error => {
            return Observable.throw(error);
          })
        )
        .subscribe(() => true);
    } else {
      return of(false);
    }
  }
}
