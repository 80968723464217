import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards";
// Import Containers
import { InspectionComponent } from "./inspections/inspection.component";
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin/admin.component";
import { MapComponent } from "./map/map.component";

export const routes: Routes = [
  {
    path: "inspections",
    canActivate: [AuthGuard],
    component: InspectionComponent,
    data: {
      title: "Home"
    }
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "admin",
    component: AdminComponent
  },
  {
    path: "map",
    component: MapComponent
  },
  {
    path: "*",
    redirectTo: "inspections",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
