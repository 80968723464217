import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapComponent } from "./map.component";
import { AgmCoreModule } from "@agm/core";

@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDB9SVruJH6V1o01X5gzms2pCiJSnPcNOE"
    })
  ],
  declarations: [MapComponent]
})
export class MapModule {}
