import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  OnInit,
  SimpleChanges
} from "@angular/core";

import { Store, Select } from "@ngxs/store";
import { HipState } from "../store/hip.state";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"]
})
export class ReportComponent implements OnInit, OnChanges {
  @Input() inspection: any;
  @Output() closeReport = new EventEmitter();
  @Select(HipState.InventoryArray) inventoryArray: Observable<any[]>;

  inventory: any;

  constructor(private store: Store) {}

  close() {
    this.closeReport.emit();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    // get inventory

    this.inventoryArray
      .pipe(
        tap(i => {
          this.inventory = i.filter(
            f => f["_id"] == changes.inspection.currentValue.unit.split("-")[0]
          )[0];
        })
      )
      .subscribe();
  }
}
