import { Component, OnInit } from "@angular/core";
import { Store, Select } from "@ngxs/store";
import { Observable } from "rxjs";

import { HipState, GetHms } from "../../store";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit {
  @Select(HipState.Components) component$: Observable<any>[];

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new GetHms());
  }
}
