import { Store, State, Action, StateContext, Selector } from "@ngxs/store";
import {
  InspectionDataService,
  InventoryDataService,
  HmsDataService,
  AttachmentDataService
} from "../services";
import { tap, catchError, filter } from "rxjs/operators";
import { Logout, AuthState, AuthStateModel } from "./auth.state";
import { of, throwError } from "rxjs";
import { Inspection } from "../models";
import * as moment from "moment";

export class GetInspections {
  static readonly type = "[Inspections] GetInspections";
  constructor(public payload: { community: string }) {}
}
export class GetInspectionById {
  static readonly type = "[Inspections] GetInspection By Id";
  constructor(public payload: { community: string; id: string }) {}
}

export class SortInspections {
  static readonly type = "[Inspections] Sorting";
  constructor(public payload: { column: string; direction: string }) {}
}

export class FilterInspections {
  static readonly type = "[Inspections] Fitler";
  constructor(public payload: { text: string }) {}
}

export class FilterInspectionsByComponent {
  static readonly type = "[Inspections] Fitler by Component";
  constructor(public payload: { id: string | false }) {}
}

export class GetInventoryById {
  static readonly type = "[Inventory] GetInventoryById";
  constructor(public payload: { id: string[] }) {}
}
export class GetAttachments {
  static readonly type = "[Inspections] GetAttachments";
  constructor(public payload: { id: string; refresh: boolean }) {}
}

export class GetHms {
  static readonly type = "[HMS] GetHms";
}

export class GetAvgCR {
  static readonly type = "[HMS] GetAvgCR";
  constructor(public payload: { community: string }) {}
}

export class LoadingError {
  static readonly type = "[HMS] LoadingError";
  constructor(public payload: { status: number; error: string }) {}
}

export class RemoveAttachment {
  static readonly type = "[HMS] Remove Attachment";
  constructor(public payload: { inspection: any; attachmentId: string }) {}
}

export interface HipStateModel {
  inventory: any;
  inspections: any;
  hms: any;
  user: any;
  attachments: any;
  loading: boolean | string;
  error: any;
  remotes: string[];
  avg_cr: number | "N/A";
  sorting: any;
  filtering: string;
  component: string | false;
}

@State<any>({
  name: "hip",
  defaults: {
    inventory: {},
    inspections: {},
    hms: {},
    user: {},
    attachments: {},
    remotes: [],
    avg_cr: 0,
    sorting: { column: "completed", direction: "desc" },
    filtering: ""
  }
})
export class HipState {
  constructor(
    private inspectionService: InspectionDataService,
    private inventoryService: InventoryDataService,
    private hmsService: HmsDataService,
    private attachmentService: AttachmentDataService,
    public store: Store
  ) {}

  @Action(GetHms)
  getHms(ctx: StateContext<HipStateModel>) {
    ctx.patchState({ loading: "Database" });
    return this.hmsService.getAll().pipe(
      catchError(err => {
        //
        console.log(err);
        this.store.dispatch(new Logout({ error: err.status }));
        return of(err.status);
      }),
      tap(data => {
        console.log(data);

        if (typeof data != "number") {
          // patch the inspection state
          ctx.patchState({
            hms: Object.assign(
              {},
              ...data.map(item => ({ [item["id"]]: item.doc }))
            ),
            loading: false
          });
        } else if (data === 404) {
          ctx.dispatch(
            new LoadingError({
              status: data,
              error: "Resource Not Found Error"
            })
          );
        } else if (data == 401 || data == 403) {
          ctx.dispatch(
            new LoadingError({
              status: data,
              error: "You are not authorized for this resource"
            })
          );
        }
      })
    );
  }

  @Action(GetInspections)
  getInspections(
    ctx: StateContext<HipStateModel>,
    { payload: { community } }: GetInspections
  ): any {
    console.log(community);
    let remote = false;

    const state = ctx.getState();
    ctx.patchState({ loading: "inspections" });
    if (state.remotes.includes(community)) remote = true;
    return this.inspectionService
      .getAll(community, remote)
      .pipe(
        catchError(err => {
          if (err.status == 404) {
            // resource not Found - set this community as remote and re-load
            ctx.patchState({ remotes: [...state.remotes, community] });
            console.log("patched");
            ctx
              .dispatch(new GetInspections({ community }))
              .toPromise()
              .then(() => ctx.dispatch(new GetAvgCR({ community })));

            return of<Inspection[]>([]);
          }
        }),
        tap(data => {
          console.log(data);

          // patch the inspection state
          ctx.patchState({
            inspections: Object.assign(
              {},
              ...data.map(item => ({ [item._id]: item }))
            ),
            loading: false
          });
        })
      )
      .subscribe((inspections: any) => {
        console.log("map", inspections.map(inv => inv.unit.split("-")[0]));

        ctx.dispatch(
          new GetInventoryById(inspections.map(inv => inv.unit.split("-")[0]))
        );
      });
  }

  @Action(GetInspectionById)
  getInspectionById(
    ctx: StateContext<HipStateModel>,
    { payload: { community, id } }: GetInspectionById
  ) {
    console.log(community);
    const state = ctx.getState();
    ctx.patchState({ loading: "inspections" });
    return this.inspectionService.getById(community, id).pipe(
      tap(data => {
        console.log(data);

        // patch the inspection state
        ctx.patchState({
          inspections: Object.assign(state.inspections, {
            [data["_id"]]: data
          }),
          loading: false
        });
      })
    );
  }

  @Action(SortInspections)
  sortInspections(
    ctx: StateContext<HipStateModel>,
    { payload: { column, direction } }: SortInspections
  ) {
    const state = ctx.getState();
    ctx.patchState({ sorting: { column, direction } });
  }

  @Action(FilterInspections)
  filterInspections(ctx: StateContext<HipStateModel>, { payload: { text } }) {
    console.log("filter", text);
    ctx.patchState({ filtering: text });
  }

  @Action(FilterInspectionsByComponent)
  filterInspectionsByComponent(
    ctx: StateContext<HipStateModel>,
    { payload: { id } }
  ) {
    console.log("comp filter", id);
    ctx.patchState({ component: id });
  }

  @Action(GetInventoryById)
  getInventoryById(
    ctx: StateContext<HipStateModel>,
    { payload }: GetInventoryById
  ) {
    const state = ctx.getState();
    console.log("getbyid:", payload);

    ctx.patchState({ loading: "inventory" });
    return this.inventoryService.getById(payload).pipe(
      tap((data: any) => {
        console.log(data);

        // patch the inspection state
        ctx.patchState({
          inventory: Object.assign(
            {},
            ...data.map(item => ({ [item["id"]]: item.doc }))
          ),
          loading: false
        });
      })
    );
  }

  @Action(GetAvgCR)
  getAvgCR(
    ctx: StateContext<HipStateModel>,
    { payload: { community } }: GetAvgCR
  ) {
    return this.inspectionService.getCommunityAverageCR(community).pipe(
      tap(data => {
        if (data) {
          ctx.patchState({ avg_cr: Math.floor(data) });
        } else {
          // no Data
          ctx.patchState({ avg_cr: "N/A" });
        }
      })
    );
  }

  @Action(GetAttachments)
  getAttachments(
    ctx: StateContext<HipStateModel>,
    { payload: { id, refresh } }: GetAttachments
  ) {
    const state = ctx.getState();
    ctx.patchState({ loading: "Attachments" });
    if (state.attachments[id] && !refresh) {
      ctx.patchState({ loading: false });
      return [state];
    }

    return this.attachmentService.getById(id).pipe(
      tap(data => {
        ctx.patchState({
          attachments: {
            ...state.attachments,
            [id]: { ...data }
          },
          loading: false
        });
      })
    );
  }

  @Action(RemoveAttachment)
  removeAttachment(
    ctx: StateContext<HipStateModel>,
    { payload: { inspection, attachmentId } }: RemoveAttachment
  ) {
    ctx.patchState({ loading: "Removing Attachment" });
    console.log(inspection, attachmentId);
    return this.attachmentService
      .removeAttachment(inspection, attachmentId)
      .pipe(
        tap(data => {
          console.log(data);
          ctx.patchState({ loading: false });
          this.store.dispatch(
            new GetInspectionById({
              community: data["community"],
              id: data["id"]
            })
          );
          this.store.dispatch(
            new GetAttachments({
              id: data["id"],
              refresh: true
            })
          );
        })
      );
  }

  @Action(LoadingError)
  loadingError(
    ctx: StateContext<HipStateModel>,
    { payload: { status, error } }: LoadingError
  ) {
    ctx.patchState({ error: { status, error } });
  }

  @Selector()
  static Loading(state) {
    return state.loading;
  }

  @Selector()
  static LoadingError(state) {
    return state.error;
  }

  @Selector()
  static HmsEntities(state) {
    return state.hms;
  }

  @Selector([AuthState])
  static Components(state, authState: AuthStateModel) {
    let user: any = authState.user;
    let templates = user.hip.templates.map(t => state.hms[t]);

    let _components = [];
    templates.forEach(t => {
      _components = _components.concat(
        Object.keys(t.schema.group.groups)
          .map(key => t.schema.group.groups[key].components)
          .reduce((accumulator, current) => accumulator.concat(current), [])
      );
    });
    _components = _components.reduce(
      (a, c) => (a.includes(c) ? a : a.concat(c)),
      []
    );

    return _components
      .map(key => {
        if (state.hms[key].doctype == "component") return state.hms[key];
      })
      .filter(x => x); // future use
  }

  @Selector()
  static Templates(state) {
    return Object.keys(state.hms)
      .map(key => {
        if (state.hms[key].doctype == "template") return state.hms[key];
      })
      .filter(x => x); // future use
  }

  @Selector()
  static Sorting(state) {
    return state.sorting;
  }

  @Selector()
  static InspectionsArray(state) {
    const _state = state.inspections;
    const _filter = state.filtering;

    console.log("select", _filter === "");
    let _inspections = Object.keys(_state).map(key => _state[key]);

    return _inspections
      .sort((a, b) => {
        let _a = a[state.sorting.column];
        let _b = b[state.sorting.column];
        // sort images
        if (state.sorting.column == "images") {
          _a = a._attachments ? Object.keys(a._attachments).length : 0;
          _b = b._attachments ? Object.keys(b._attachments).length : 0;
        }

        // sort UCR
        if (state.sorting.column == "UCR") {
          _a = a.UCR && a.UCR.watson ? a.UCR.watson : 0;
          _b = b.UCR && b.UCR.watson ? b.UCR.watson : 0;
        }

        if (state.sorting.column == "Mold") {
          _a = JSON.stringify(a).indexOf('"Mold":"Mold"') != -1 ? 1 : 0;
          _b = JSON.stringify(b).indexOf('"Mold":"Mold"') != -1 ? 1 : 0;
        }

        if (state.sorting.column == "Tenant Damage") {
          _a = JSON.stringify(a).indexOf('"TD"') != -1 ? 1 : 0;
          _b = JSON.stringify(b).indexOf('"TD"') != -1 ? 1 : 0;
        }

        if (state.sorting.column == "health_safety") {
          _a =
            a.warnings && a.warnings.health && a.warnings.health.length > 0
              ? 1
              : 0;
          _b =
            b.warnings && b.warnings.health && b.warnings.health.length > 0
              ? 1
              : 0;
        }

        // sort unit
        if (state.sorting.column == "unit") {
          _a =
            state.inventory[a.unit.split("-")[0]].units[a.unit.split("-")[1]]
              .number;
          _b =
            state.inventory[b.unit.split("-")[0]].units[b.unit.split("-")[1]]
              .number;

          return state.sorting.direction == "asc"
            ? _a > _b
              ? 1
              : -1
            : _a < _b
            ? 1
            : -1;
        }
        // sort building
        if (state.sorting.column == "building") {
          _a = state.inventory[a.unit.split("-")[0]].address.number;
          _b = state.inventory[b.unit.split("-")[0]].address.number;
          return state.sorting.direction == "asc"
            ? _a > _b
              ? 1
              : -1
            : _a < _b
            ? 1
            : -1;
        }
        // sort template
        if (state.sorting.column == "template") {
          _a = a.template ? state.hms[a.template].name : "CRS";
          _b = b.template ? state.hms[b.template].name : "CRS";
          return state.sorting.direction == "asc"
            ? _a > _b
              ? 1
              : -1
            : _a < _b
            ? 1
            : -1;
        }

        return state.sorting.direction == "asc" ? _a - _b : _b - _a;
      })
      .filter(inspection => {
        if (state.component) {
          var _comp = state.component;
          var _include = false;
          if (
            inspection.completed != false &&
            inspection.schema &&
            inspection.schema.group.groups
          ) {
            //console.log(inspection);
            // itterate through groups and find this component that matches.
            let locations = Object.keys(inspection.schema.group.groups).map(
              key => inspection.schema.group.groups[key]
            );
            locations.forEach(loc => {
              //console.log(loc, _include);
              if (
                Object.keys(loc.components).filter(key => key === _comp)[0] &&
                loc.components[
                  Object.keys(loc.components).filter(key => key === _comp)[0]
                ].selected[
                  state.hms[_comp].criteria.filter(crit => crit.default)[0].id
                ] !=
                  state.hms[_comp].criteria.filter(crit => crit.default)[0].id
              ) {
                _include = true;
              }
            });
          }
          return _include;
        } else {
          return _filter === ""
            ? true
            : state.inventory[
                inspection.unit.split("-")[0]
              ].address.number.indexOf(_filter) != -1;
        }
      });
  }

  @Selector()
  static InventoryArray(state) {
    const _state = state.inventory;
    return Object.keys(_state).map(key => _state[key]);
  }

  @Selector()
  static InventoryEntities(state) {
    return state.inventory;
  }

  @Selector()
  static Attachments(state) {
    return state.attachments;
  }

  @Selector()
  static GetAverageCR(state) {
    return state.avg_cr;
  }

  @Selector()
  static InspectionStats(state) {
    const _state = state.inspections;
    let inspections = Object.keys(_state).map(key => _state[key]);
    if (inspections.length == 0) return [0, 0, 0];

    return inspections.reduce((acc, item) => {
      // [ sheduled, started, complete ]
      let a = [
        !item.started && !item.completed ? 1 : 0,
        item.started && !item.completed ? 1 : 0,
        item.completed && item.completed + 31536000000 > moment().valueOf()
          ? 1
          : 0
      ];
      //check for initial state (acc = object)
      if (acc._id)
        acc = [
          !acc.started ? 1 : 0,
          acc.started && !item.completed ? 1 : 0,
          acc.completed ? 1 : 0
        ];
      return a.map((a, idx) => a + acc[idx]);
    });
  }
}
