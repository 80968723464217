import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "menu-component",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"]
})
export class MenuComponent {
  @Input() // list of communities for community selector
  communities: string[];

  @Input() // list of statuses for status selector
  status: string[];

  @Output() switchCommunity = new EventEmitter();

  selectCommunity(community) {
    this.switchCommunity.emit(community);
  }
}
