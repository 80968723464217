import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of, throwError } from "rxjs";
import {
  map,
  pluck,
  tap,
  mergeAll,
  combineAll,
  catchError
} from "rxjs/operators";
import { HMS } from "../models";
import { Store } from "@ngxs/store";

@Injectable()
export class HmsDataService {
  constructor(private http: HttpClient, private store: Store) { }

  getAll(): Observable<any> {
    let headers = this.store.selectSnapshot(state => state.auth.xauth);

    return this.http
      .get(`https://hms-db.nunavuthousing.ca/hms/_all_docs?include_docs=true`, {
        headers: headers
      })
      .pipe(
        catchError(err => {
          throw (err);
        }),
        tap(x => console.log(x)),
        pluck("rows")
      );

    //)

    //return arr;
  }
}
